import classNames from 'classnames'
import { format, isAfter } from 'date-fns'
import { useEffect, useState } from 'react'

export const EventSalesView = ({
  dateString,
}: {
  dateString: string | null | undefined
}) => {
  const [str, setStr] = useState<string | null>(null)

  useEffect(() => {
    if (!dateString) {
      setStr('発売中')
      return
    }

    const date = new Date(dateString)
    const now = new Date()

    if (isAfter(now, date)) {
      setStr('発売中')
      return
    }

    setStr(`${format(date, 'M月d日 HH:mm')}より発売`)
  }, [dateString])

  return (
    <div
      className={classNames(
        'w-full text-xs text-center mt-1 py-1 font-bold',
        {
          'bg-neutral-200': str !== '発売中',
          'bg-[#0093E544]': str === '発売中',
        },
        'text-[#101010]',
      )}
    >
      {str}
    </div>
  )
}
